import React from 'react'

const MuniPrivacyPolicyTR = () => {
  return (
    <div className='legal_content'>
      <div className='legal_anchor' />
      <span className='legal_content_header'>
        Kişisel Verilerin Korunması Kanunu ve Uygulanmasına Dair Bilgilendirme
      </span>

      <span className='legal_content_body_text'>
        İşbu Kişisel Veriler Aydınlatma Metni, 24.03.2016 kabul tarihli ve 6698
        sayılı Kişisel Verilerin Korunması Kanunu (Bundan sonra KVKK olarak
        yazılacaktır) uyarınca kişisel verilerinizin toplanması, işlenmesi,
        aktarılması ve KVKK kapsamındaki haklarınıza dair platform
        kullanıcılarına yönelik bilgi notudur.
      </span>

      <span className='legal_content_body_text'>
        Yıldız Teknik Üniversitesi Yıldız Teknopark D2 Blok No: 151 /1F İç Kapı
        No: 2B03 Esenler/ İSTANBUL adresinde yerleşik
        <b>Peny Yazılım ve Teknoloji Anonim Şirketi </b>(bundan sonra “Şirket”
        veya “PENY” olarak anılacaktır) olarak veri sorumlusu sıfatıyla kişisel
        verilerinizi KVKK ve ilgili mevzuata uygun olarak aşağıda açıklanan
        kapsamda işliyoruz.
      </span>

      <span className='legal_content_body_title'>
        Verilerinizi niye işliyoruz?
      </span>

      <span className='legal_content_body_text'>
        PENY olarak verilerinizi saklama ve işlememizdeki temel sebebimiz size
        hizmetlerimizi sunabilmek ve sunduğumuz hizmetleri iyileştirmektir. Bu
        bağlamda verilerinizi KVKK’nın 5. Maddesine uygun olarak işliyoruz.
      </span>

      <span className='legal_content_body_text'>
        Sizin açık rızanızı aldığımız durumlarda verilerinizi pazarlama
        faaliyetleri ve bilgilendirme için de kullanmaktayız.
      </span>

      <span className='legal_content_body_text'>
        Verilerinizi işleme amaçlarımızı daha iyi anlamak istiyorsanız
        <b>, Kişisel Verilerin İşlenmesi ve Korunması Politika’mızı </b>
        inceleyebilirsiniz.
      </span>

      <span className='legal_content_body_title'>
        Verilerinizi nasıl topluyoruz?
      </span>

      <span className='legal_content_body_text'>
        Verilerinizi temel olarak Peny’nin sunduğu uygulamaları (mobil veya
        internet bazlı) ve Peny’nin sitelerini (
        <a href='http://www.muni.com.tr/' target='_blank' rel='noreferrer'>
          https://www.muni.com.tr
        </a>
        {'  '}ve {'  '}
        <a href='http://www.muni.com.tr/' target='_blank' rel='noreferrer'>
          https://www.usemuni.com
        </a>
        ) kullandığımız zaman topluyoruz. Hesap oluşumu, hesapların
        kullanılması, uygulamalar veya siteler üzerinden bize iletişime
        geçilmesi gibi durumlarda verilerinizi elektronik ortamda toplamalıyız.
      </span>

      <span className='legal_content_body_text'>
        Ancak elektronik ortamın dışında SMS, kartvizitler, telefon, faks,
        fiziki form, internet sitemiz, uzaktan erişim sağlayan platformlar,
        posta, kargo ya da kurye hizmetleri, yüz yüze görüşmeler, diğer fiziki
        ve elektronik ortamlar aracılığıyla da verilerinizi toplayabilmekteyiz.
      </span>

      <span className='legal_content_body_title'>
        Verilerinizi başka kime aktarıyoruz?
      </span>

      <span className='legal_content_body_text'>
        PENY, şirket politikası olarak kullanıcı verilerini mümkün oldukça
        üçüncü şahıs veya kurumlara aktarmamaktadır. PENY’nin, kullanıcı
        verilerini üçüncü şahıs veya kurumlarla
      </span>
      <span className='legal_content_body_text'>
        paylaşmak zorunda kaldığı durumlar kullanıcıların PENY hizmetlerini
        kullanabilmesi, bu hizmetlerden iyi faydalanabilmesi ve PENY’nin
        hizmetlerini geliştirebilmesi içindir.
      </span>

      <span className='legal_content_body_text'>
        Bu doğrultuda PENY hizmetlerinden faydalandığı üçüncü kişi
        tedarikçilere, kanunen yetkili kamu kurumlarına ve kanunen yetkili özel
        kişilere KVKK’nın 5. maddesinde düzenlenen veri işleme şartları
        kapsamında KVKK’nın 8. maddesinde belirtilen kişisel verilerin
        aktarılmasına ilişkin kurallara uygun ve amaçlarla sınırlı olmak üzere
        kullanıcıya ait verileri aktarabilmektedir.
      </span>

      <span className='legal_content_body_text'>
        Eğer kullanıcı açık rıza gösterirse, PENY kullanıcı bilgilerini
        pazarlama ve bilgilendirme amaçları doğrultusunda iş ortakları ile
        paylaşabilmektedir.
      </span>

      <span className='legal_content_body_title'>Haklarınız nelerdir?</span>

      <span className='legal_content_body_text'>
        İlgili Kişi olarak, KVKK’nın 11. maddesi uyarınca kişisel verilerinize
        ilişkin aşağıdaki haklara sahipsiniz:
      </span>
      <ul id='l1'>
        <li>
          <span className='legal_content_body_text'>
            Kişisel verilerinizin işlenip işlenmediğini öğrenme,
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            Kişisel verilerin işlenme amacını ve bunların amacına uygun
            kullanılıp kullanılmadığını öğrenme,
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı
            üçüncü kişileri bilme,
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde
            bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
            kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini
            isteme,
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            6698 sayılı KVKK ve ilgili diğer kanun hükümlerine uygun olarak
            işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
            kalkması hâlinde kişisel verilerinizin silinmesini veya yok
            edilmesini isteme ve bu kapsamda yapılan işlemin, kişisel
            verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
            edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz
            etme,
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
            uğramanız halinde bu zararın giderilmesini talep etme.
          </span>
        </li>
      </ul>
      <span className='legal_content_body_text'>
        Bu haklarınıza ilişkin taleplerinizi{' '}
        <a
          href='mailto:help@muni.com.tr'
          className='a'
          target='_blank'
          rel='noreferrer'
        >
          help@muni.com.tr
        </a>
        {'  '}adresi üzerinden gönderebilirsiniz.
      </span>
      <span className='legal_content_body_text'>
        Başvurunuz ücretsiz olarak yerine getirilecektir. Ancak talep edilen
        işlemin ayrıca bir maliyet gerektirmesi halinde, Kişisel Verileri Koruma
        Kurulu tarafından belirlenen tarifedeki ücretler başvurandan talep
        edilecektir.
      </span>
      <span className='legal_content_body_text'>
        Başvurularınız, talebin niteliğine göre mümkün olan en kısa sürede ve en
        geç otuz gün içinde yazılı veya elektronik ortamdan cevaplanacaktır.
        Talebinizin reddedilmesi halinde, başvurunuz gerekçeli olarak
        yanıtlanacaktır.
      </span>
      <span className='legal_content_body_text'>
        Daha fazla bilgi alabilir miyim?
      </span>
      <span className='legal_content_body_text'>
        Her türlü soru ve görüşlerinizi{' '}
        <a href='mailto:help@muni.com.tr' target='_blank' rel='noreferrer'>
          help@muni.com.tr{'  '}
        </a>
        adresi üzerinden bize iletebilirsiniz. Verilerinizin nasıl işlendiğini
        ve korunduğunu daha iyi anlamak için Kişisel Verilerin İşlenmesi ve
        Korunması Politika’mızı da inceleyebilirsiniz.
      </span>
      <div className='legal_content_ending_container'></div>
    </div>
  )
}

export default MuniPrivacyPolicyTR
