import React from 'react'

const MuniTermsAndConditions = () => {
  return (
    <div className='legal_content'>
      <div className='legal_anchor' />
      <span className='legal_content_header'>Hizmet Şartları</span>

      <span className='legal_content_body_text'>
        Bu Hizmet Şartları, <a href='http://www.muni.com.tr/' class='a' target='_blank' rel="noreferrer">https://www.muni.com.tr</a> adresinde bulunan web sitemizin ve Peny Yazılım ve Teknoloji A.Ş. tarafından sağlanan ilgili hizmetlerin kullanımını yönetir.
      </span>

      <span className='legal_content_body_text'>
        <a href='http://www.muni.com.tr/' class='a' target='_blank' rel="noreferrer">https://www.muni.com.tr</a><a href='http://www.muni.com.tr/' class='s3' target='_blank' rel="noreferrer">,</a> adresine erişerek bu Hizmet Şartlarını ve tüm geçerli yasaları ve düzenlemeleri kabul etmeyi taahhüt edersiniz. Bu Hizmet Şartlarını kabul etmiyorsanız, bu web sitesini kullanmanız veya erişmeniz veya Peny Yazılım ve Teknoloji A.Ş. tarafından sağlanan başka hizmetleri kullanmanız yasaktır.
      </span>

      <span className='legal_content_body_text'>
        Biz, Peny Yazılım ve Teknoloji A.Ş., bu Hizmet Şartlarının herhangi birini kendi takdirimize bağlı olarak gözden geçirme ve değiştirme hakkını saklı tutarız. Bunu yaparken, bu sayfayı güncelleyeceğiz. Bu Hizmet Şartlarındaki herhangi bir değişiklik, yayınlandığı tarihten itibaren hemen yürürlüğe girer.
      </span>

      <span className='legal_content_body_text'>
        Bu Hizmet Şartları son olarak 06 Aralık 2021 tarihinde güncellenmiştir.
      </span>

      <span className='legal_content_body_title'>Kullanım Sınırlamaları</span>

      <span className='legal_content_body_text'>
        Bu web sitesini kullanarak, kendiniz, kullanıcılarınız ve temsil ettiğiniz diğer taraflar adına, şunları yapmayacağınıza garanti verirsiniz:
      </span>

      <ol id='l1'>
        <li>
          <span className='legal_content_body_text'>
            bu web sitesinde bulunan herhangi bir materyali veya yazılımı değiştirmek, kopyalamak, türev eserler hazırlamak, deşifre etmek veya tersine mühendislik yapmak;
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            bu web sitesindeki herhangi bir materyalden veya yazılımdan telif hakkı veya diğer mülkiyet haklarına dair notları kaldırmak;
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            materyalleri başka bir kişiye aktarmak veya materyalleri başka bir sunucuda “ayna” olarak kullanmak;
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            bu web sitesini veya ilişkili hizmetlerini bilerek veya ihmal ederek ağlarımızı veya Peny Yazılım ve Teknoloji A.Ş. tarafından sağlanan herhangi bir hizmeti kötüye kullanacak veya bozacak şekilde kullanmak;
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            bu web sitesini veya ilişkili hizmetlerini taciz edici, müstehcen, dolandırıcı veya yasa dışı herhangi bir materyali iletmek veya yayımlamak için kullanmak;
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            bu web sitesini veya ilişkili hizmetlerini, geçerli yasaları veya düzenlemeleri ihlal ederek kullanmak;
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            bu web sitesini yetkisiz reklamcılık veya spam gönderimi ile birlikte kullanmak;
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            kullanıcının rızası olmadan kullanıcı verilerini toplamak, derlemek veya bir araya getirmek; veya
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            bu web sitesini veya ilişkili hizmetlerini, üçüncü tarafların gizlilik haklarını, fikri mülkiyet haklarını veya diğer haklarını ihlal edecek şekilde kullanmak.
          </span>
        </li>
      </ol>

      <span className='legal_content_body_title'>Fikri Mülkiyet</span>

      <span className='legal_content_body_text'>
        Bu web sitesinde yer alan materyallerdeki fikri mülkiyet, Peny Yazılım ve Teknoloji A.Ş. tarafından sahip olunan veya lisanslanmıştır ve geçerli telif hakkı ve marka hukuku ile korunmaktadır. Kullanıcılarımıza, materyallerin kişisel, ticari olmayan geçici kullanımı için bir kopyasını indirme izni veriyoruz.
      </span>

      <span className='legal_content_body_text'>
        Bu, bir unvan devri değil, bir lisans verilmesidir. Bu lisans, bu kısıtlamalardan herhangi birini veya Hizmet Şartlarını ihlal etmeniz durumunda otomatik olarak sona erer ve Peny Yazılım ve Teknoloji A.Ş. tarafından herhangi bir zamanda sonlandırılabilir.
      </span>

      <span className='legal_content_body_title'>Sorumluluk</span>

      <span className='legal_content_body_text'>
        Web sitemiz ve web sitemizdeki materyaller 'olduğu gibi' temelinde sağlanmaktadır. Yasaların izin verdiği ölçüde, Peny Yazılım ve Teknoloji A.Ş. herhangi bir garanti vermez, açık veya zımni garantileri reddeder ve sınırlama olmaksızın, ticarî, belirli bir amaca uygunluk veya fikri mülkiyet ihlali olmamak veya diğer hakların ihlali olmamak gibi zımni garantiler veya koşulları reddeder.
      </span>

      <span className='legal_content_body_text'>
        Hiçbir durumda Peny Yazılım ve Teknoloji A.Ş. veya tedarikçileri, bu web sitesini veya bu web sitesindeki materyalleri kullanmaktan veya kullanamamaktan kaynaklanan herhangi bir dolaylı zarar için sorumlu tutulamaz, hatta Peny Yazılım ve Teknoloji A.Ş. veya yetkili bir temsilci bu tür zararların olasılığı konusunda sözlü veya yazılı olarak bilgilendirilmiş olsa bile.
      </span>
      <span className='legal_content_body_text'>
        Bu anlaşma bağlamında “dolaylı zarar” herhangi bir dolaylı zarar, gerçek veya beklenen kar kaybı, fayda kaybı, gelir kaybı, iş kaybı, itibar kaybı, fırsat kaybı, tasarruf kaybı, kullanım kaybı ve/veya veri kaybı veya bozulması gibi zararları içerir, ister yasa, sözleşme, eşitlik, tazminat, tort (ihmal dahil) veya başka türlü olsun.
      </span>

      <span className='legal_content_body_text'>
        Bazı yargı bölgeleri, zımni garantilere ilişkin sınırlamaları veya dolaylı veya tesadüfi zararlar için sorumluluk sınırlamalarını izin vermediği için, bu sınırlamalar sizin için geçerli olmayabilir.
      </span>

      <span className='legal_content_body_title'>Materyallerin Doğruluğu</span>

      <span className='legal_content_body_text'>
        Web sitemizde yer alan materyaller kapsamlı değildir ve yalnızca genel bilgi amaçlıdır. Peny Yazılım ve Teknoloji A.Ş., bu web sitesindeki materyallerin kullanımının doğruluğu, muhtemel sonuçları veya güvenilirliği veya bu tür materyallerle veya bu web sitesine bağlantılı kaynaklarla ilgili olarak herhangi bir beyanda bulunmaz veya garanti vermez.
      </span>

      <span className='legal_content_body_title'>Bağlantılar</span>

      <span className='legal_content_body_text'>
        Peny Yazılım ve Teknoloji A.Ş., web sitesine bağlantı verilen tüm siteleri incelemedi ve bu tür bağlantılı sitelerin içeriğinden sorumlu değildir. Herhangi bir bağlantının dahil edilmesi, Peny Yazılım ve Teknoloji A.Ş. tarafından sitenin onaylanması, onayı veya kontrolü anlamına gelmez. Herhangi bir bağlantılı siteyi kullanmak tamamen kendi riskenizdir ve bu sitelerin uygunluğu konusunda kendi araştırmalarınızı yapmanızı şiddetle tavsiye ederiz.
      </span>

      <span className='legal_content_body_title'>Sonlandırma Hakkı</span>

      <span className='legal_content_body_text'>
        Bu Hizmet Şartlarını ihlal etmeniz durumunda web sitemizi kullanma hakkınızı askıya alabilir veya sonlandırabilir ve bu Hizmet Şartlarını derhal yazılı bildirimle sonlandırabiliriz.
      </span>

      <span className='legal_content_body_title'>Ayrılabilirlik</span>
      <span className='legal_content_body_text'>
        Bu Hizmet Şartlarının tamamen veya kısmen geçersiz veya uygulanabilir olmayan herhangi bir hükmü, geçersiz veya uygulanabilir olmayan ölçüde ayrılır. Bu Hizmet Şartlarının geri kalanının geçerliliği etkilenmez.
      </span>

      <span className='legal_content_body_title'>Uygulanacak Hukuk</span>

      <span className='legal_content_body_text'>
        Bu Hizmet Şartları, Türkiye yasalarına göre yönetilir ve yorumlanır. Kesin olarak, bu Devlet veya yerin mahkemelerinin özel yargı yetkisine boyun eğersiniz.
      </span>
      <div className='legal_content_ending_container'></div>
    </div>

  )
}

export default MuniTermsAndConditions
