import React from "react"

//Component
import Legal from "../../components/Pages/Legal"
import Layout from "../../components/Layout"
const LegalPage = () => {
  return (
    <Layout>
      <Legal />
    </Layout>
  )
}

export default LegalPage
