/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react"

//React redux
import { useSelector } from "react-redux"

// Components
import MuniAcceptableUsePolicyEN from "../Tools/Legal/MuniAcceptableUsePolicyEN"
import MuniAcceptableUsePolicyTR from "../Tools/Legal/MuniAcceptableUsePolicyTR"
import MuniCookiePolicyEN from "../Tools/Legal/MuniCookiePolicyEN"
import MuniCookiePolicyTR from "../Tools/Legal/MuniCookiePolicyTR"
import MuniPrivacyPolicyEN from "../Tools/Legal/MuniPrivacyPolicyEN"
import MuniPrivacyPolicyTR from "../Tools/Legal/MuniPrivacyPolicyTR"
import MuniTermsAndConditionsEN from "../Tools/Legal/MuniTermsAndConditionsEN"
import MuniTermsAndConditionsTR from "../Tools/Legal/MuniTermsAndConditionsTR"
import MuniAccountDeletionRequestEN from "../Tools/Legal/MuniAccountDeletionRequestEN"
import MuniAccountDeletionRequestTR from "../Tools/Legal/MuniAccountDeletionRequestTR"

//Localized Data
import LegalLocalized from "../../localized/legalLocalized.json"
import Helmet from "react-helmet"
import favIcon from "../../assets/muni_favicon.ico"

const Legal = () => {
  const [currentLegalPage, setCurrentLegalPage] = useState(4)
  const currentLanguage = useSelector(state => state.changeLanguageReducer)


  const scrollIntoView = () => {
    const anchor = document.querySelector(".legal_anchor")
    anchor.scrollIntoView({
      behavior: "smooth",
    })
  }

  const renderLegalPage = () => {
    if (currentLanguage === "en") {
      switch (currentLegalPage) {
        case 0:
          return <MuniAcceptableUsePolicyEN />
        case 1:
          return <MuniCookiePolicyEN />
        case 2:
          return <MuniPrivacyPolicyEN />
        case 3:
          return <MuniTermsAndConditionsEN />
        case 4:
          return <MuniAccountDeletionRequestEN />
        default:
          return <MuniAcceptableUsePolicyEN />
      }
    } else {
      switch (currentLegalPage) {
        case 0:
          return <MuniAcceptableUsePolicyTR />
        case 1:
          return <MuniCookiePolicyTR />
        case 2:
          return <MuniPrivacyPolicyTR />
        case 3:
          return <MuniTermsAndConditionsTR />
        case 4:
          return <MuniAccountDeletionRequestTR />
        default:
          return <MuniAcceptableUsePolicyTR />
      }
    }
  }

  return (
    <div id="legal-section">
      <Helmet>
        <title>Peny</title>
        <meta name="icon" href={favIcon} />
        <meta
          name="description"
          content="Rydoo Alternative. Peny helps you stay on top of your company's expenses. Scan, manage, and track your expenses. Great expense management at no cost."
        />
        <meta name="keywords" content="expense management, concur, scan receipts, rydoo, per diem, expenses, expense tracker" />
        <meta property="og:title" content="Peny" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Rydoo Alternative. Peny helps you stay on top of your company's expenses. Scan, manage, and track your expenses. Great expense management at no cost."
        />
        {/* <meta property="og:image" content="" /> */}
        <meta property="og:locale" content="en_UK" />
        <meta property="og:url" content="https://usemuni.com" />
        <link rel="canonical" href="https://usemuni.com" />
        <link rel="icon" href={favIcon} />
      </Helmet>
      <div id="legal-section-container">
        <div id="legal-pages-navigation">
          <div id="legal-pages-navigation-header">
            <span>
              {LegalLocalized.agreementsAndPolicies?.[currentLanguage]}
            </span>
          </div>
          <div id="legal-pages-navigation-tabs">
            <span
              onClick={() => {
                scrollIntoView()
                setCurrentLegalPage(0)
              }}
              className={`legal_navigation_title ${
                currentLegalPage === 0 ? "active_legal_title" : ""
              }`}
            >
              {LegalLocalized.acceptableUsePolicy?.[currentLanguage]}
            </span>
            <span
              onClick={() => {
                scrollIntoView()
                setCurrentLegalPage(1)
              }}
              className={`legal_navigation_title ${
                currentLegalPage === 1 ? "active_legal_title" : ""
              }`}
            >
              {LegalLocalized.cookiePolicy?.[currentLanguage]}
            </span>
            <span
              onClick={() => {
                scrollIntoView()
                setCurrentLegalPage(2)
              }}
              className={`legal_navigation_title ${
                currentLegalPage === 2 ? "active_legal_title" : ""
              }`}
            >
              {LegalLocalized.privacyPolicy?.[currentLanguage]}
            </span>
            <span
              onClick={() => {
                scrollIntoView()
                setCurrentLegalPage(3)
              }}
              className={`legal_navigation_title ${
                currentLegalPage === 3 ? "active_legal_title" : ""
              }`}
            >
              {LegalLocalized.termsAndConditions?.[currentLanguage]}
            </span>
            <span
              onClick={() => {
                scrollIntoView()
                setCurrentLegalPage(4)
              }}
              className={`legal_navigation_title ${
                currentLegalPage === 4 ? "active_legal_title" : ""
              }`}
            >
              {LegalLocalized.accountDeletion?.[currentLanguage]}
            </span>
          </div>
        </div>
        <div id="legal-text-section">{renderLegalPage()}</div>
      </div>
    </div>
  )
}

export default Legal
