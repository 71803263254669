import React from 'react'

import loginSS from "../../../assets/company/appSS_login.png"
import homeSS from "../../../assets/company/appSS_home.png"
import settingsSS from "../../../assets/company/appSS_settings.png"
import myAccountSS from "../../../assets/company/appSS_myAccount.png"
import privacyDeleteSS from "../../../assets/company/appSS_privacyDelete.png"

const MuniTermsAndConditions = () => {
  return (
    <div className='legal_content'>
      <div className='legal_anchor' />
      <span className='legal_content_header'>Hesap Silme Talebi</span>

      <span className='legal_content_body_text'>
        Birçok ülkede mevcut olan, Gizlilik ve Veri Koruma yönetmeliklerine göre, Peny'deki hesabınızı ve <b>tüm kişisel bilgilerinizi</b> silme talebinde bulunabilirsiniz.
      </span>

      <span className='legal_content_body_text'>
        Ancak, Peny uygulamalarına girdiğiniz kişisel olmayan bilgilerin size ait olmadığını belirtmemiz gerekiyor. Bu bilgiler şirketinize aittir ve yalnızca şirketinizin isteği üzerine silinebilir.
      </span>

      <span className='legal_content_body_title'>Hesap Silme Talebi Nasıl Yapılır</span>

      <span className='legal_content_body_text'>
        <b className='legal_content_body_text_heading'>Adım 1</b><br/>Hesabınızı silme talebinde bulunmak için mobil uygulamalarımızı kullanmanız gerekmektedir. Peny uygulamanıza giriş yaparak işe başlayın. Silme talebinde bulunma özelliğinin eski sürümlerde bulunmadığını unutmayın ve lütfen uygulamanızın en son sürümü kullanın.
      </span>
      <span className='legal_content_body_text'>
        <b className='legal_content_body_text_heading'>Adım 2</b><br/>Giriş yaptıktan sonra, uygulamanızın alt gezintisini kullanarak 'Menü' bölümüne gidin.
      </span>
      <span className='legal_content_body_text'>
        <b className='legal_content_body_text_heading'>Adım 3</b><br/>'Menü' bölümünde sunulan birçok seçenek arasından 'Hesabımı' seçin.
      </span>
      <span className='legal_content_body_text'>
        <b className='legal_content_body_text_heading'>Adım 4</b><br/>Hesabınızın bulunduğu bölümde, size uygun olan 'Hesap Silme Talebi' seçeneğini bulacaksınız.
      </span>
      <span className='legal_content_body_text'>
        <b className='legal_content_body_text_heading'>Adım 5</b><br/>Bu son adımdır. Burada, gizlilik nedeniyle hesabınızı silme talebinin ne anlama geldiğini okuyabilirsiniz. Hazır olduğunuzda, hesabınızın silinmesi için bu metni tıklayın.
      </span>

      <span className='legal_content_body_title'>Adımların Açıklamalı Gösterimi</span>
      
      <div className='legal_media'>
          <img src={loginSS} alt="Giriş Ekran Görüntüsü" /> 
          <img src={homeSS} alt="Giriş Ekran Görüntüsü" /> 
          <img src={settingsSS} alt="Giriş Ekran Görüntüsü" /> 
          <img src={myAccountSS} alt="Giriş Ekran Görüntüsü" /> 
          <img src={privacyDeleteSS} alt="Giriş Ekran Görüntüsü" /> 
      </div>

      <div className='legal_content_ending_container'></div>
    </div>
  )
}

export default MuniTermsAndConditions
