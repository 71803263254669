import React from 'react'

const MuniAcceptableUsePolicy = () => {
  return (
    <div className='legal_content'>
      <div className='legal_anchor' />
      <span className='legal_content_header'>Kabul Edilebilir Kullanım Politikası</span>

      <span className='legal_content_body_text'>
        Bu kabul edilebilir kullanım politikası, Peny Yazılım ve Teknoloji tarafından sağlanan ürünleri, hizmetleri ve teknolojileri (topluca "Ürünler" olarak anılır) kapsar.
      </span>
      <span className='legal_content_body_text'>
        Bu politika, bizi, müşterilerimizi ve genel İnternet topluluğunu etik dışı, sorumsuz ve yasa dışı faaliyetlerden korumak için tasarlanmıştır.
      </span>

      <span className='legal_content_body_text'>
        Peny Yazılım ve Teknoloji A.Ş. müşterileri, bu kabul edilebilir kullanım politikasında yasaklanan faaliyetlerde bulunduğu tespit edilirse hizmet askıya alınması ve hesap terminasyonundan sorumlu olabilir. Aşırı durumlarda, ilgili yetkililere bu tür müşterileri bildirmek zorunda kalabiliriz.
      </span>

      <span className='legal_content_body_text'>
        Bu politika son olarak 06 Aralık 2021 tarihinde gözden geçirilmiştir.
      </span>

      <span className='legal_content_body_title'>Adil Kullanım</span>

      <span className='legal_content_body_text'>
        Tesislerimizi, teklif programımıza göre "normal iş" olarak kullanılacağı varsayımıyla sağlıyoruz. Kullanımınız aşırı olarak kabul edilirse, ek ücretler talep edilebilir veya kapasite kısıtlanabilir.
      </span>

      <span className='legal_content_body_text'>
        Her türlü istismara, ayrımcılığa, hak ihlaline ve/veya herhangi bir grubu, bireyi veya kaynağı zarara uğratan veya dezavantaj sağlayan her türlü eyleme karşıyız. Müşterilerimizden ve ilgili oldukları durumlarda kullanıcılarından ("son kullanıcılar") Ürünlerimizi benzer bir niyetle kullanmalarını bekliyoruz.
      </span>

      <span className='legal_content_body_title'>Müşteri Sorumluluğu</span>

      <span className='legal_content_body_text'>
        Müşterilerimizi kendi eylemlerinden ve izinleri dahilinde Ürünlerimizi kullanan herkesin eylemlerinden sorumlu olarak görüyoruz. Bu sorumluluk, müşterinin makul güvenlik önlemleri almaması sonucu yetkisiz şekilde Ürünlerimizi kullanan herkes için de geçerlidir.
      </span>

      <span className='legal_content_body_text'>
        Müşterilerimizden Ürünlerimizi kabul etmeleri durumunda, son kullanıcıları adına bu politikaya uyulmasını sağlamayı kabul ederler.
      </span>
      <span className='legal_content_body_text'>
        Müşterilerin veya son kullanıcılarının eylemleri hakkındaki şikayetler, ilgili hesap için belirlenen kişiye iletilir.
      </span>

      <span className='legal_content_body_text'>
        Bir müşteri — veya onların son kullanıcısı veya müşteri sonucu Ürünlerimizi kullanan herhangi biri — kabul edilebilir kullanım politikamızı ihlal ederse, suçlu hesapla veya hesapla ilişkili herhangi bir Ürünü sonlandırma veya uygun gördüğümüz herhangi bir düzeltici veya önleyici önlem alma hakkımızı saklı tutarız, haber vermeden. Kanunen mümkün olan ölçüde, politikamızın ihlali sonucu servis kesintileri için hiçbir kredi sağlanmayacaktır.
      </span>

      <span className='legal_content_body_title'>Yasaklanmış Faaliyet</span>

      <span className='legal_content_body_title'>
        Telif Hakkı İhlali ve Yetkisiz Materyale Erişim
      </span>

      <span className='legal_content_body_text'>
        Ürünlerimiz, geçerli yasa dışı herhangi bir materyali iletmek, dağıtmak veya depolamak için kullanılamaz. Bu, izin alınmadan kullanılan telif hakkı, marka, ticari sır veya diğer fikri mülkiyet hakkı korumasındaki herhangi bir materyali içerir ve sınırlı değildir.
      </span>

      <ol id='l1'>
        <li>
          <span className='legal_content_body_text'>
            telif hakkı, marka, ticaret sırrı veya diğer fikri mülkiyet hakkı koruması altındaki herhangi bir materyali uygun izin almadan kullanmak ve
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            müstehcen, iftira niteliğindeki, yasadışı tehdit içeren veya ihracat kontrol yasalarını ihlal eden herhangi bir materyal.
          </span>

          <span className='legal_content_body_text'>
            Müşteri, Ürünlerimiz aracılığıyla veya Ürünlerimizde girilen, yüklenen, yayılan, iletilen, oluşturulan veya yayınlanan tüm materyallerden tek başına sorumludur ve bu tür materyallerde kullanılan eserler için yasal izin almakla yükümlüdür.
          </span>

          <span className='legal_content_body_title'>
            SPAM ve Yetkisiz Mesaj Faaliyeti
          </span>

          <span className='legal_content_body_text'>
            Ürünlerimiz, yargı bölgenizde geçerli yasalar ve yönetmelikler ("spam") ihlal edilerek istenmeyen toplu veya ticari mesajlar göndermek amacıyla kullanılamaz. Bu, diğer hizmet sağlayıcılardan gönderilen spam'den müşteri teklif etmek, ve diğer hizmet sağlayıcıların gönderdiği spam'e cevap toplamak gibi faaliyetleri içerir ve sınırlı değildir.
          </span>

          <span className='legal_content_body_text'>
            Ürünlerimiz, onaylanmamış posta listeleri veya telefon numarası listeleri çalıştırmak amacıyla kullanılamaz ("mesaj listeleri"). Bu, e-posta adreslerinin veya telefon numaralarının sahibinin izni olmadan herhangi bir mesaj listesine abone olmayı ve bu şekilde abone olan e-posta adreslerini veya telefon numaralarını depolamayı içerir ve sınırlı değildir. Ürünlerimizde çalıştırılan veya barındırılan tüm mesaj listeleri "onaylı opt-in" olmalıdır. Mesaj listesinin ömrü boyunca adres veya telefon numarası sahibinin açık izninin doğrulanması mevcut olmalıdır.
          </span>

          <span className='legal_content_body_text'>
            Spam veya onaylanmamış mesaj listesi amaçları için üçüncü taraflardan satın alınan e-posta listeleri, telefon numarası listeleri veya veritabanları Ürünlerimizde kullanılamaz.
          </span>

          <span className='legal_content_body_text'>
            Bu spam ve yetkisiz mesaj faaliyeti politikası, Ürünlerimiz kullanılarak gönderilen mesajlara veya müşteri tarafından veya müşteri adına başka bir ağdan gönderilen ve alıcıyı Ürünlerimiz aracılığıyla barındırılan bir siteye yönlendiren mesajlara uygulanır.
          </span>

          <span className='legal_content_body_title'>
            Etik dışı, sömürücü ve kötü niyetli faaliyet
          </span>

          <span className='legal_content_body_text'>
            Ürünlerimiz, bu kabul edilebilir kullanım politikasını veya diğer hizmet sağlayıcıların kabul edilebilir kullanım politikalarını ihlal etmek için tasarlanmış herhangi bir yazılım, program, ürün veya hizmeti tanıtmak, iletmek veya başka bir şekilde sağlamak amacıyla kullanılamaz. Bu, spam göndermeyi ve ağ taraması, ping atma, paket sahteciliği, sel basması, posta bombalama ve hizmet reddi saldırıları başlatmayı kolaylaştıran araçları içerir ve sınırlı değildir.
          </span>

          <span className='legal_content_body_text'>
            Ürünlerimiz, grubu veya bireyi, hesaba veya elektronik kaynağa erişim sağlamak için kullanılamaz; bu grubun veya bireyin erişim sahibi olmadığı veya erişim yetkisi olmadığı durumlar (örn. "hacking", "cracking", "phreaking" vb.).
          </span>

          <span className='legal_content_body_text'>
            Ürünlerimiz ve sistemlerimize kasıtlı veya dikkatsizce virüsler veya kötü niyetli kod sokmak amacıyla kullanılamaz.
          </span>

          <span className='legal_content_body_text'>
            Ürünlerimiz, başka bir grup veya bireyi taciz etmek amacıyla kasıtlı olarak kullanılamaz. Taciz tanımımız, hizmet reddi saldırıları, nefret söylemi, ırksal veya etnik hoşgörüsüzlüğü teşvik etme ve herhangi bir grubun veya bireyin haklarını tehdit etme, kötüye kullanma, ihlal etme veya ayrımcılık yapma faaliyetlerini içerir.
          </span>
          <span className='legal_content_body_text'>
            Etik dışı, sömürücü ve kötü niyetli olarak kabul edilen diğer faaliyetler şunları içerir:
          </span>

          <ol id='l2'>
            <li>
              <span className='legal_content_body_text'>
                Ödeme yapmaktan kaçınma niyetiyle bizden hizmet almak (veya almak için çalışmak);
              </span>
            </li>
            <li>
              <span className='legal_content_body_text'>
                Başka bir sağlayıcıdan ödeme yapmaktan kaçınma niyetiyle hizmet almak (veya almak için çalışmak) amacıyla tesislerimizi kullanmak;
              </span>
            </li>
            <li>
              <span className='legal_content_body_text'>
                Müşterilerimizin veya son kullanıcılarının bilgilerine yetkisiz erişim, değiştirme veya yok etme (veya bunu deneme) eylemleri;
              </span>
            </li>
            <li>
              <span className='legal_content_body_text'>
                Tesislerimizi ve ağımızı kullanma hakları olan diğer müşteriler veya yetkili kişiler tarafından kullanımı engellemek amacıyla tesislerimizi kullanmak;
              </span>
            </li>
            <li>
              <span className='legal_content_body_text'>
                Şiddeti teşvik eden, şiddet eylemini tasvir eden, çocuk pornografisi içeren veya herhangi bir kişinin sağlık ve güvenliğini tehdit eden içerik veya bağlantıları yayınlamak veya iletmek;
              </span>
            </li>
            <li>
              <span className='legal_content_body_text'>
                Tüketici koruma yasaları ve yönetmeliklerine aykırı herhangi bir eylem veya ihmali;
              </span>
            </li>
            <li>
              <span className='legal_content_body_text'>
                Bir kişinin gizliliğinin ihlali.
              </span>
            </li>
          </ol>
        </li>
      </ol>

      <span className='legal_content_body_text'>
        Ürünlerimiz, yasadışı kumar, terörizm, uyuşturucu ticareti, silah ticareti veya nükleer, kimyasal veya biyolojik silahların, kitlesel imha silahlarının veya füzelerin yayılması, geliştirilmesi, tasarımı, üretimi, üretimi, stoklanması veya kullanımı ile ilgili faaliyetlerle veya nedenlerle ilgili veya bu tür faaliyetleri veya nedenleri destekleyen herhangi bir kişi veya kuruluş tarafından kullanılamaz.
      </span>

      <span className='legal_content_body_title'>
        Peny Yazılım ve Teknoloji A.Ş. mülkiyetinin izinsiz kullanımı
      </span>

      <span className='legal_content_body_text'>
        Peny Yazılım ve Teknoloji A.Ş.'yi taklit etmek, Peny Yazılım ve Teknoloji A.Ş. ile önemli bir iş ilişkisi olduğunu temsil etmek veya Peny Yazılım ve Teknoloji A.Ş. mülkiyetine (Ürünlerimiz ve markamız dahil) sahip olmak amacıyla hizmet, müşteri, himaye veya kullanıcı güveni kazanmak için dolandırıcılık yapmak kesinlikle yasaktır.
      </span>
      <span className='legal_content_body_title'>Bu Politika Hakkında</span>

      <span className='legal_content_body_text'>
        Bu politika, markamızla uyumsuz ve kabul edilemez olarak değerlendirdiğimiz niyetlerin ve faaliyetlerin kapsamlı olmayan bir listesini sunar.
      </span>

      <span className='legal_content_body_text'>
        Bu politikayı herhangi bir zamanda, web sitemizde revize edilmiş sürümü yayınlayarak değiştirme hakkımızı saklı tutarız. Revize edilmiş sürüm, şu tarihlerden daha önceki bir tarihte yürürlüğe girecektir:
      </span>

      <ul id='l3'>
        <li>
          <span className='legal_content_body_text'>
            Müşteri, web sitemizde revize edilmiş sürümü yayınladıktan sonra Ürünlerimizi kullandığında; veya
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            Web sitemizde revize edilmiş sürümü yayınladıktan sonra 30 gün sonra.
          </span>
        </li>
      </ul>
      <div className='legal_content_ending_container'></div>
    </div>
  )
}

export default MuniAcceptableUsePolicy
